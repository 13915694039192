
@font-face {
  font-family: 'AspireSC-Regular';
  src: url('/fonts/AspireSC-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('/fonts/IBMPlexSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('/fonts/IBMPlexSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('/fonts/IBMPlexSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('/fonts/IBMPlexSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
}

body {
  background-color: black;
  margin: 0;
  font-family: IBM Plex Sans;
  font-weight: 400;
  color: white;

  @media (max-width: 1279px) {
    font-size: 13px;
  }
}

.image__container {
  width: 100%;

  img {
    width: 100%;
    height: auto;
  }
}

.home-hero {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: calc(100vh - 10px);
  max-height: 952px;
  width: 100%;
  align-items: flex-end;
  justify-content: center;
  border-bottom: 1px solid #097bfb;

  @media(max-width: 1279px) {
    max-height: 702px;
  }

  @media(max-width: 1152px) {
    max-height: 552px;
  }

  @media(max-width: 900px) {
    max-height: 452px;
  }

  @media(max-width: 600px) {
    max-height: auto;
    height: auto;
    padding: 80px 0 223px;
    margin-top: 59px;
  }
}

.techn_bg {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  color: white;
  background: url('/assets/network_bg.jpg') no-repeat center center; 
  background-size: cover;
}

.techn_bg::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); 
  z-index: 0;
}


.home__section-subject {
  font-family: 'IBM Plex Sans';
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  background: linear-gradient(252.34deg, #087BFB 20.28%, #9CCAFD 76.12%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-transform: uppercase;
  margin: 0 0 20px 0;

  @media (max-width: 1279px) {
    font-size: 18px;
  }
}

.home__section-title {
  font-size: 36px;
  font-family: IBM Plex Sans;
  font-weight: 700;
  margin-bottom: 20px;

  @media (max-width: 1279px) {
    font-size: 24px;
  }
}

.home__values-title {
  font-size: 36px;
  font-family: IBM Plex Sans;
  font-weight: 700;
  margin-top: 60px;

  @media (max-width: 1279px) {
    font-size: 24px;
  }
}

.hero__background {
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-height: 496px;
  height: 100%;
  width: auto;
  position: absolute;
  z-index: -1;
  max-width: 100%;

  @media (max-width: 1279px) {
    max-height: 300px;
  }

  @media (max-width: 800px) {
    max-height: 200px;
  }

  @media (max-width: 600px) {
    max-height: 150px;
  }
}

.hero__title {
  font-family: 'AspireSC-Regular';
  font-size: 32px;
  line-height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #ECF5FF;
  margin-bottom: 20px;
  display: inline;

  @media (max-width: 1279px) {
    font-size: 22px;
    line-height: 29px;
    margin-bottom: 15px;
  }

  @media (max-width: 600px) {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 40px;
  }
}

.hero__info {
  display: flex;
  flex-direction: column;
  gap: 260px;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media(max-width: 1152px) {
    gap: 160px;
  }

  @media(max-width: 900px) {
    gap: 120px;
  }

  @media(max-width: 600px) {
    gap: 64px;
  }
}

.hero__text {
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;
  width: 100%;
}

.hero__title span {
  background: linear-gradient(252.34deg, #087BFB 20.28%, #9CCAFD 76.12%), linear-gradient(0deg, #ECF5FF, #ECF5FF);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero__subtitle {
  max-width: 585px;
  text-align: center;  
  font-size: 23px;
  margin-bottom: 50px;

  @media (max-width: 1279px) {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 0;
    padding: 0 40px;
  }
}

.hero__subtitle span{
  background: linear-gradient(252.34deg, #087BFB 50.28%, #9CCAFD 96.12%), linear-gradient(0deg, #ECF5FF, #ECF5FF);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero__button {
  display: flex;
  padding: 16px 48px;
  box-shadow: 0px 0px 75px #087BFB, 0px 5px 95px #087BFB;  
  border: 1px solid #087BFB;
  border-radius: 40px;
  background-color: rgba(1, 12, 25, 1);
  font-weight: 600;
  font-family: 'IBM Plex Sans';
  cursor: pointer;
  color: white;
  text-decoration: none;
}

.home-why {
  background: black;
  min-width: 100%;
  border-top: 1px solid #097bfb;
  border-bottom: 1px solid #097bfb;

  @media (max-width: 900px) {
    border-top: 3px solid #097bfb;
  }
}

.home-why__container {
  position: relative;
  display: flex;
  max-width: 1200px;
  margin: auto;
  justify-content: space-between;
  align-items: center;
  gap: 60px;
  flex-direction: row-reverse;
  padding: 52px 0;

  @media (max-width: 1090px) {
    padding: 70px 0 79px;
  }

  @media (max-width: 1045px) {
    padding: 48px 0 62px;
  }

  @media (max-width: 990px) {
    padding: 22px 0 42px;
  }

  @media (max-width: 990px) {
    padding: 22px 0 42px;
  }

  @media (max-width: 958px) {
    padding: 15px 0 32px;
  }

  @media (max-width: 900px) {
    flex-direction: column;
    padding: 0 0 40px;
    gap: 30px;
  }
}

.home-why--column {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media (max-width: 1279px) {
    padding-left: 40px;
  }

  @media (max-width: 900px) {
    align-items: center;
    text-align: center;
    padding: 0 40px;
  }
}

.partners--column {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-left: 20px;

  @media (max-width: 1279px) {
    padding-left: 40px;
  }

  @media (max-width: 900px) {
    align-items: center;
    text-align: center;
    padding: 0 40px;
  }
}

.logo{
  padding-top: 20px;
}

.logo1{
  padding-top: 15px;
}

.home-why--row {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  padding-left: 0%;
}

.home__text {
  width: 450px;
  font-family: IBM Plex Sans;
  font-weight: 400;

  @media (max-width: 1279px) {
    width: auto;
  }
}

.chip {
  width: auto;
  height: 100%;
  margin-top: -72px;
  margin-bottom: -115px;
  margin-right: -40px;

  @media (max-width: 1279px) {
    max-height: 500px;
  }

  @media (max-width: 1090px) {
    max-height: 420px;
  }

  @media (max-width: 1045px) {
    max-height: 380px;
  }

  @media (max-width: 990px) {
    max-height: 310px;
  }

  @media (max-width: 900px) {
    max-height: 200px;
    margin-right: 0px;
    
  }
}

.home-why__image {
  width: auto;
  height: 100%;
  margin-top: -72px;
  margin-bottom: -115px;
  margin-right: -40px;

  @media (max-width: 1279px) {
    max-height: 500px;
  }

  @media (max-width: 1090px) {
    max-height: 420px;
  }

  @media (max-width: 1045px) {
    max-height: 380px;
  }

  @media (max-width: 990px) {
    max-height: 310px;
  }

  @media (max-width: 900px) {
    max-height: 200px;
    display: none;    
  }
}

.home-why--mobile {
  position: relative;
  width: 100%;
  @media (min-width: 901px) {
    display: none;
  }
}

.home-why__image--mobile {
  position: relative;
  margin-top: -2px;
  width: 100%;
  z-index: 20;
}

.home-why__image--mobile-line {
  position: absolute;
  height: 1px;
  background: #097bfb;
  width: 100%;
  bottom: 20%;
  z-index: 0;
}


.technology {
  position: relative;
  margin: 70px auto;
  max-width: 950px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 400px;


  @media (max-width: 1279px) {
    padding-right: 40px;
  }

  @media (max-width: 900px) {
    padding: 0 40px;
    width: auto;
    align-items: center;
    margin: 20px auto 0;
    text-align: center;
  }
}

.technology__image {
  position: absolute;
  width: 1032.25px;
  height: 1326.44px;
  left: -229px;
  top: -350px;
  z-index: -1;

  @media (max-width: 1279px) {
    left: -129px;
    top: -250px;
    width: 732.25px;
    height: 926.44px;
  }

  @media (max-width: 900px) {
    display: none;
  }
}

.technology__image--mobile {
  height: 400px;
  max-width: 310px;
  width: 100%;
  background: url("/assets/transcending.svg");
  background-position: -33px -45px;
  margin-bottom: -50px;
  background-size: 400px;
  background-repeat: no-repeat;

  @media (min-width: 901px) {
    display: none;
  }
}

.technology__section-subject {
  margin-top: 100px;
  @media (max-width: 900px) {
    margin-top: 0px;
  }
}

.technology__title {
  max-width: 700px;

  @media (max-width: 900px) {
    text-align: center;
  }
}

.technology__text {
  display: flex;
  align-items: flex-start;
  gap: 18px;

  @media (max-width: 900px) {
    flex-direction: column;
  }
}

.technology__paragraph {
  max-width: 365px;
  font-family: IBM Plex Sans;
  font-weight: 400;
  
}

.technology__paragraph--span {
  color: rgba(8, 123, 251, 1);
}

.products__section-subject {
  margin-top: 50px; 
  margin-bottom: 10px; 
  @media (max-width: 900px) {
    margin-top: 20px;
    margin-bottom: 30px; 
    text-align: center;
  }
}

.products__button {
  display: inline-flex;
  padding: 10px 24px;
  box-shadow: 0px 0px 75px #087BFB, 0px 5px 95px #087BFB;  
  border: 1px solid #087BFB;
  border-radius: 40px;
  background-color: rgba(1, 12, 25, 1);
  font-weight: 600;
  font-family: 'IBM Plex Sans';
  font-size: small;
  cursor: pointer;
  color: white;
  text-decoration: none;
  margin-top: 40px;
}


.location__column {
  position: relative;
  text-align: center;
  align-items: center;
}

.location {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  z-index: 2;
  width: 100%;
}

.location__title {
  font-size: 24px;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 10px;
  margin: 0 auto;
  display: inline-block;
}

.location__text {
  font-size: 18px;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
  display: inline-block;
}

@media (max-width: 1279px) {
  .location__title {
    font-size: 18px;
  }
  .location__text {
    font-size: 15px;
  }
}


@media (max-width: 900px) {
  .location__column {
    flex-direction: column;
    padding: 70px 20px;
  }
  .home__section-subject {
    top: 20px; 
    left: 20px; 
    font-size: 1.2rem;
  }
}

.faq__section {
  padding: 150px;
  border-bottom: 1px solid #097bfb;
  margin-bottom: 100px;
  
  @media (max-width: 900px) {
    padding: 0px;
    margin-bottom: 0px;
    }
}

.faq {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  max-width: 1400px;
  margin: 0 auto;
  padding: 70px 40px;

  @media (max-width: 900px) {
    flex-direction: column;
    padding: 70px 20px;
  }
}

.faq__title {
  max-width: 463px;
}

.faq__column {
  display: flex;
  flex-direction: column;

  @media (max-width: 900px) {
    max-width: 365px;
    text-align: center;
    margin-bottom: 20px;
  }
}

.faq__question {
  background: transparent;
  color: white;
  outline: none;
  border: 0;
  width: 456px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 20px 0;

  @media (max-width: 1279px) {
    width: 312px;
    font-size: 12px;
  }
}

.faq__answer {
  font-size: 14px;
  color: white;
  padding: 0 40px 20px 16px;

  @media (max-width: 1279px) {
    font-size: 12px;
  }
}

.faq__container {
  display: flex;
  flex-direction: column;
  max-width: 455px;
  border-bottom: 1px solid white;
  margin-right: 40px;

  @media (max-width: 900px) {
    margin-right: 0;
    max-width: 312px;
  }
}

.faq__button-sign {
  width: 10px;
}

.team__background_grid {
  background: url("/assets/Matrix-Grid.png");
}

.team__section {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 40px 40px 80px 40px;
  margin-top: 100px;
}

.team__section-bkg {
  position: absolute;
  top: 150px;
  left: 0;
  right: 0;
  margin: auto;
  height: 562px;
  z-index: -1;
  max-width: 1290px;

  @media (max-width: 1600px) {
    max-width: 100%;
  }

  @media (max-width: 900px) {
    display: none;
  }
}

.team__title {
  max-width: 502px;
  margin-bottom: 40px;
}

.team__description {
  max-width: 739px;
  height: auto;
  font-style: Regular;
  text-align: center;
  font-family: IBM Plex Sans;
  font-weight: 400;
  line-height: 136.50000095367432%;
  font-stretch: normal;
  text-decoration: none;
}

.team__cards-background {
  left: 0;
  right: 0;
  margin: auto;
  position: absolute;
  z-index: -1;
}

.team__card {
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding: 45px;
  border-bottom: 1px solid white;

  @media (max-width: 900px) {
    padding: 15px 18px;
  }
}

.team__card--active {
  transition: all 0.3s ease-in-out;
  max-height: 400px;
}


.team__card-bio {
  font-size: 14px;
  color: #fff;
  margin-top: 10px;
  max-width: 500px;
  margin: 0 auto; 

  @media (max-width: 900px) {
    font-size: 8px;
    max-width: 300px;
  }

  @media (max-width: 530px) {
    font-size: 8px;
    max-width: 250px;
  }

  @media (max-width: 480px) {
    font-size: 8px;
    max-width: 200px;
  }

  @media (max-width: 430px) {
    font-size: 8px;
    max-width: 150px;
  }

}


.home-dashed-line {
  position: absolute;
  width: 100%;
  height: 1px;
  background-image: repeating-linear-gradient(
    to right,
    white,
    white 100px,
    transparent 100px,
    transparent 200px
  );
  z-index: -1;
  overflow: hidden;

  @media (max-width: 900px) {
    display: none;
  }
}

.team-dashed-line--1 {
  bottom: -140px;

  @media (max-width: 900px) {
    display: none;
  }
}

.team-dashed-line--2 {
  bottom: -370px;

  @media (max-width: 900px) {
    display: none;
  }
}

.team-dashed-line--3 {
  bottom: -600px;

  @media (max-width: 900px) {
    display: none;
  }
}

.team__card:first-child {
  border-top: 1px solid white;
}

.team__card--active {
  background: conic-gradient(from 35.85deg at 66.33% 27.54%, #033164 0deg, #033164 43.04deg, #054A97 71.96deg, #9CCAFD 180deg, #087BFB 224.56deg, #0662C9 279.69deg, #033164 360deg);
}

.team {
  padding: 40px 0 240px 0;
}

.team__cards {
  display: flex;
  flex-direction: column;
  max-width: 870px;
  margin: 0 auto;

  @media (max-width: 900px) {
    padding: 0 40px;
    max-width: 470px;
  }
}

.team__card--image {
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-shrink: 1;
  right: 90px;

  @media (max-width: 900px) {
    width: 100px;
    height: 100px;
    right: 25px;
  }
}

.team__card--rect-image {
  top: 188.2020263671875px;
  position: absolute;
  z-index: -1;
}

.team__card-text {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.team__card-name {
  font-size: 32px;
  line-height: 46px;
  font-family: IBM Plex Sans;
  font-weight: 700;

  @media (max-width: 1279px) {
    font-size: 16px;
    line-height: 21px;
  }
}

.team__card-position {
  font-size: 20px;
  line-height: 31px;
  font-family: IBM Plex Sans;
  font-weight: 400;
  margin-top: 4px;

  @media (max-width: 1279px) {
    font-size: 9px;
    line-height: 11px;
  }
}

.home-contact {
  position: relative;
  padding: 200px 40px;
  gap: 71px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;

  @media (max-width: 900px) {
    padding: 40px;
    flex-direction: column;
    align-items: flex-start;
  }
}

.home-contact__column {
  position: relative;
  gap: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media (max-width: 900px) {
    width: 100%;
  }
}

.home-contact__form {
  gap: 22px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  width: 100%;
}

.home-contact__input {
  color: white;
  width: 456px;
  display: flex;
  padding: 14px 15px;
  align-items: flex-start;
  flex-shrink: 0;
  border: 1px solid white;
  font-size: 14px;
  background: transparent;
  outline: none;
  box-sizing: border-box;
  border-radius: 20px;

  @media (max-width: 900px) {
    font-size: 13px;
    width: 100%;
    min-width: -webkit-fill-available;
  }
}

.home-contact__input:hover {
  border: 1px solid #087BFB;
}

.home-contact__input:focus {
  border: 1px solid #087BFB;
  box-shadow: 0px 0px 30px 3px #087BFB;
}

.home-contact__textarea {
  height: 95px;
  font-family: IBM Plex Sans;
  font-weight: 400;
}

.home-contact__textarea::placeholder,
.home-contact__input::placeholder {
  color: white;
}

.home-contact__button {
  color: white;
  gap: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: transparent;
  border: 0;
  cursor: pointer;
}

.home-contact__button:active {
  background: linear-gradient(252.34deg, #087BFB 20.28%, #9CCAFD 76.12%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text
}

#home-contact__button-arrow svg{
  fill: blue!important;
  stroke: tomato;
}

.home-contact__button:hover {
  text-decoration: underline;
  text-decoration-color: #087BFB;
}

.home-rectangle179 {
  top: 16px;
  left: 0px;
  width: 57px;
  height: 1px;
  position: absolute;
}

.home-contact__info {
  position: relative;
  gap: 49px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media (max-width: 900px) {
    gap: 20px;
  }
}

.home-contact__text {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.home-contact__title {
  margin-bottom: 40px;
}

.home-contact__paragraph {
  max-width: 367px;
}

.home-contact__socials {
  display: flex;
  align-items: flex-start;
  margin-left: 20px;
  visibility: hidden;

  @media (max-width: 900px) {
    margin: 0;
  }
}

.home-contact__social {
  width: 24px;
  height: 24px;
  margin-right: 25px;

  @media (max-width: 900px) {
    margin-right: 16px;
  }
}

.footer {
  position: relative;
  display: flex;
  justify-content: center;
  gap: 115px;
  flex-shrink: 0;
  padding: 150px 0;

  @media (max-width: 900px) {
    padding: 40px;
    gap: 50px;
    flex-direction: column;
    align-items: center;
  }
}

.footer__background {
  position: absolute;
  top: 10%;
  left: 0;
  right: 0;
  width: 100%;
  height: 80%;
  background: linear-gradient(180deg, #088CFB 74.79%, #9CCAFD 99.48%);
  filter: blur(100px);
  z-index: -1;
  transform: translate(0%, 10%);
}

.footer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-image: repeating-linear-gradient(
    to right,
    white,
    white 100px,
    transparent 100px,
    transparent 200px
  );

  @media (max-width: 1023px) {
    background-image: repeating-linear-gradient(
    to right,
    white,
    white 55px,
    transparent 55px,
    transparent 110px
  );
  }
}

.footer__title {
  margin: 0;
}

.footer__info {
  gap: 40px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media (max-width: 1279px) {
    max-width: 212px;
  }

  @media (max-width: 900px) {
    align-items: center;
    text-align: center;
    max-width: 212px;
  }
}

.footer__text {
  max-width: 465px;
  font-size: 17px;
  font-family: IBM Plex Sans;
  font-weight: 400;

  @media (max-width: 1279px) {
    font-size: 14px;
  }
}

.footer__button {
  background: white;
  color: black;
  display: flex;
  padding: 16px 48px;
  align-items: flex-start;
  border-radius: 90px;
  font-family: IBM Plex Sans;
  font-weight: 600;
  text-decoration: none;

  @media (max-width: 1279px) {
    font-size: 10px;
    padding: 8px 22px;
  }
}

.footer__links {
  gap: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  
  @media (max-width: 900px) {
    align-items: center;
  }
}

.footer__link {
  color: white;
  font-family: IBM Plex Sans;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
}

.home-values {
  border-top: 1px solid #087BFB;
  border-bottom: 1px solid #087BFB;
  position: relative;
  background: black;
}

.home-values::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 1px;
  background-image: repeating-linear-gradient(
    to right,
    white,
    white 100px,
    transparent 100px,
    transparent 200px
  );
}

.home-values__container {
  gap: 17px;
  max-width: 1700px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 180px;
  padding: 40px 0 70px;
  margin: auto;

  @media (max-width: 900px) {
    flex-direction: column;
    padding: 27px 40px 67px;
  }
}

.home-values__summary {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media (max-width: 900px) {
    margin-bottom: 30px;
  }
}

.home-values__title {
  margin-bottom: 32px;  
  max-width: 368px;
}

.home-values__text {
  max-width: 368px;
  font-weight: 400;
  align-items: center;
  padding-left: 250px;


@media (max-width: 1024px) {
    max-width: 320px;
    padding-left: 200px;
}

@media (max-width: 768px) {
    max-width: 280px;
    padding-left: 150px;

}

@media (max-width: 480px) {
    max-width: 100%;
    padding-left: none;
    text-align: center; 
}
}

.home-values__cards {
  gap: 40px;
  display: flex;
}

.home-values__card {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 100;

  @media (max-width: 900px) {
    margin-left: 37px;
  }
}

.home-values__card:last-child {
  margin-right: 35px;
}

.home-values__card-content {
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 18px;
  gap: 160px;

  @media (max-width: 900px) {
    padding: 10px 18px;
    gap: 124px;
  }
}

.home-values__card-title {
  font-size: 23px;
  line-height: 30px;
  font-family: IBM Plex Sans;
  font-weight: 500;
  max-width: 200px;
  text-transform: uppercase;
}

.home-values__card-description {
  color: rgba(255, 255, 255, 1);
  max-width: 268px;
  font-size: 14px;
  font-family: IBM Plex Sans;
  font-weight: 400;
}

@media (max-width: 900px) {
  .home-values__container {
    .carousel-container {
      width: 100%!important;
    }

    .prev-button {
      left: 20px!important
    }
  
    .next-button {
      left: 60px!important
    }     
  }
} 

.home-values__card-interrupted {
  position: absolute;
  width: 100%;
  height: 180px;
  background-image: repeating-linear-gradient(
    90deg,
    #ffffff25,
    #ffffff25 8px,
    transparent 2px,
    transparent 10px
  );
  background-clip: padding-box;
  backdrop-filter: blur(7.5px);
}

.home-values__ellipse {
  top: 20px;
  width: 246px;
  height: 227px;
  border-radius: 50%;
  position: absolute;
  z-index: -1;

  @media (max-width: 900px) {
    width: 176px;
  }
}

.home-values__ellipse-1 {
  background: conic-gradient(from -46.41deg at 55.42% 30.83%, #000000 0deg, #087BFB 360deg);
  transform: matrix(-0.88, 0.47, 0.47, 0.88, 0, 0);
}

.home-values__ellipse-2 {
  background: conic-gradient(from 159.95deg at 55.42% 30.83%, #000000 0deg, #087BFB 360deg);
  transform: matrix(-0.88, 0.47, 0.47, 0.88, 0, 0);
}

.home-values__ellipse-3 {
  background: conic-gradient(from 121.84deg at 55.42% 30.83%, #000000 0deg, #087BFB 360deg);
  transform: matrix(-0.88, 0.47, 0.47, 0.88, 0, 0);
}

.home-values__ellipse-4 {
  background: conic-gradient(from 35.64deg at 55.42% 30.83%, #000000 0deg, #087BFB 360deg);
  transform: matrix(-0.88, 0.47, 0.47, 0.88, 0, 0);  
}

.home-values__ellipse-5 {
  background: conic-gradient(from 253.45deg at 55.42% 30.83%, #000000 0deg, #087BFB 360deg);
  transform: matrix(-0.88, 0.47, 0.47, 0.88, 0, 0);
}

.home-values__card-description {
  width: 280px;
  font-size: 14px;
  font-weight: 400;

  @media (max-width: 900px) {
    width: 200px;
  }
}

.home-applications {
  position: relative;
  padding-left: 400px;

  @media (max-width: 1279px) {
    padding: 0 40px;
  }
}

@media (max-width: 900px) {
  .home-applications {
    .carousel-container {
      width: 100%!important;
    }

    .prev-button {
      top: auto!important;
      bottom: -50px!important;
      left: calc(50% - 44px)!important    
    }
  
    .next-button {
      top: auto!important;
      bottom: -50px!important;
      left: calc(50% - 4px)!important
    }     
  }
} 

.home-applications__container {
  gap: 20px;
  max-width: 950px;
  display: flex;
  align-items: flex-start;
  margin: auto;
  padding: 66px 0;
  

  @media (max-width: 900px) {
    max-width: 400px;
    padding: 18px 0;
    flex-direction: column;
    align-items: center;
  }
}

.home-applications::before,
.home-applications::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  background-image: repeating-linear-gradient(
    to right,
    white,
    white 100px,
    transparent 100px,
    transparent 200px
  );

  @media (max-width: 900px) {
    display: none;
  }
}

.home-applications__container::after {
  bottom: 0;
}

.home-applications__text {
  max-width: 273px;
  height: 319px;
  margin-top: 0;

  @media (max-width: 900px) {
    max-width: 365px;
    height: auto;
    text-align: center;
    margin-top: 0; 
  }
}


.home-applications__cards {
  gap: 20px;
  display: flex;
  align-items: stretch;
}

.home-applications__card-title {
  max-width: 210px;
  font-size: 23px;
  font-weight: 500;
  text-transform: uppercase;
}

.home-applications__card-title1 {
  max-width: 210px;
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
}

.home-applications__card {
  gap: 39px;
  display: flex;
  padding: 20px 12px;
  overflow: hidden;
  align-items: flex-start;
  flex-direction: column;
  background: linear-gradient(29.04deg, #033164 16.66%, 
    #033164 29.48%, #054A97 38.1%, #9CCAFD 70.28%, 
    #087BFB 83.55%, #0662C9 99.97%);
}

.home-applications__card-2 {
  background: radial-gradient(152.32% 153.18% at 0% 95.92%, #021932 0%, #033164 11.96%, #054A97 28.77%, #B5D7FE 63.67%, #087BFB 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
}

.home-applications__card-3 {
  background: conic-gradient(from 118.41deg at 90% 61.83%, #087BFB -2.6deg, #6CB1FD 54.98deg, #B5D7FE 125.07deg, #087BFB 171.08deg, #B5D7FE 306.59deg, #087BFB 357.4deg, #6CB1FD 414.98deg);
}

.home-applications__card-description {
  width: 200px;
  font-size: 14px;
  font-weight: 400;
}

.contact-ellipse-1 {
  box-sizing: border-box;
  position: absolute;
  top: -50px;
  left: -20px;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: radial-gradient(50% 50% at 50% 50%, #087BFB95 0%, rgba(8, 123, 251, 0) 100%);
  border: 1px solid #0F58A9;
  filter: drop-shadow(0px 0px 5px #087BFB);
  z-index: -1;

  @media (max-width: 1279px) {
    display: none;
  }
}

.contact-ellipse-2 {
  box-sizing: border-box;
  position: absolute;
  top: 0px;
  right: -120px;
  width: 169px;
  height: 169px;
  border-radius: 50%;
  background: radial-gradient(50% 50% at 50% 50%, #087BFB99 0%, rgba(8, 123, 251, 0) 100%);
  border: 1.2px solid #0F58A9;
  filter: drop-shadow(0px 0px 15px #087BFB);
  z-index: -1;

  @media (max-width: 1279px) {
    display: none;
  }
}

.contact-ellipse-3 {
  box-sizing: border-box;
  position: absolute;
  bottom: -50px;
  right: -150px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: radial-gradient(50% 50% at 50% 50%, #087BFB95 0%, rgba(8, 123, 251, 0) 100%);
  border: 1px solid rgba(15, 88, 169, 0.5);
  filter: drop-shadow(0px 0px 5px #087BFB);
  z-index: -1;
  
  @media (max-width: 1279px) {
    display: none;
  }
}

.footer__logo {
  position: relative;
}

.contact-ellipse-4 {
  box-sizing: border-box;
  position: absolute;
  top: -130px;
  left: 100px;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: radial-gradient(50% 50% at 50% 50%, #087BFB95 0%, rgba(8, 123, 251, 0) 100%);
  border: 1px solid rgba(15, 88, 169, 0.5);
  filter: drop-shadow(0px 0px 5px #087BFB);
  z-index: -1;
  
  @media (max-width: 1279px) {
    display: none;
  }
}

.cookie {
  max-width: 1100px;

  &__title {
    margin-bottom: 20px;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
  }

  &__description {
    font-size: 16px;
  }
}

.CookieConsent {
  align-items: center!important;
  justify-content: center!important;
  

  div {
    max-width: 1100px;
    align-items: center;
    justify-content: center;
  }

  button:hover {
    border: 1px solid #097bfb!important;
  }
}

.tech__title {
  max-width: 700px;
  font-size: 70px;
  font-family: IBM Plex Sans;
  font-weight: 700;
  margin-top: 60px;
  margin-bottom: 20px;

  @media (max-width: 1279px) {
    font-size: 24px;
  }

  @media (max-width: 900px) {
    text-align: center;
  }
}

.tech__subtitle{
  max-width: 1000px;
  text-align: center;  
  font-size: 18px;
  margin-bottom: 50px;
  font-family: IBM Plex Sans;

  @media (max-width: 1279px) {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 0;
    padding: 0 40px;
  }
}

.facts__title{
  max-width: 700px;
  font-size: 20px;
  font-family: IBM Plex Sans;
  font-weight: 700;
  margin-top: 60px;
  margin-bottom: 20px;

  @media (max-width: 1279px) {
    font-size: 24px;
  }

  @media (max-width: 900px) {
    text-align: center;
  }
}

.facts__subtitle{

  font-family: IBM Plex Sans;

  max-width: 500px;
  @media (max-width: 1279px) {
    font-size: 24px;
  }

  @media (max-width: 900px) {
    text-align: center;
  }
}

.tech-cards{
  margin-top: 100px;
}

.tech__cards {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 320px;
  border-radius: 24px;
  line-height: 1.6;
  transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
  margin: 80px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 36px;
  border-radius: 22px;
  color: #ffffff;
  overflow: hidden;
  background: #3A96FA;
  transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
  align-items: center;
}

.content::before {
  position: absolute;
  content: "";
  top: -4%;
  left: 50%;
  width: 90%;
  height: 90%;
  transform: translate(-50%);
  background: #ced8ff;
  z-index: -1;
  transform-origin: bottom;

  border-radius: inherit;
  transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
}

.content::after {
  position: absolute;
  content: "";
  top: -8%;
  left: 50%;
  width: 80%;
  height: 80%;
  transform: translate(-50%);
  background: #e7ecff;
  z-index: -2;
  transform-origin: bottom;
  border-radius: inherit;
  transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
}

.content svg {
  width: 48px;
  height: 48px;
}

.content .para {
  z-index: 1;
  opacity: 1;
  font-size: 13px;
  text-align: center;
  transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
}

.content .link {
  z-index: 1;
  color: #fea000;
  text-decoration: none;
  font-family: inherit;
  font-size: 16px;
  transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
}

.content .link:hover {
  text-decoration: underline;
}

.tech__cards:hover {
  transform: translate(0px, -16px);
}

.tech__cards:hover .content::before {
  rotate: -8deg;
  top: 0;
  width: 100%;
  height: 100%;
}

.tech__cards:hover .content::after {
  rotate: 8deg;
  top: 0;
  width: 100%;
  height: 100%;
}

.tech__cards{
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  padding-left: 0%;
}

.card__row{
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  padding-left: 0%;

  @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
  }
  
  @media (min-width: 769px) and (max-width: 1024px) {
      justify-content: space-around;
  }
  
  @media (min-width: 1025px) {
      justify-content: space-between;
  }
}

.tech__cardTitle{
  font-size: 25px;
  font-family: 'IBM Plex Sans';
  font-weight: 600;
  text-align: center;
}

.tech__row{

  margin: 100px;
  
  @media (max-width: 1279px) {
    max-width: 500px;
  }

  @media (max-width: 1090px) {
    max-width: 420px;
  }

  @media (max-width: 1045px) {
    max-width: 380px;
  }

  @media (max-width: 990px) {
    max-width: 310px;
  }

  @media (max-width: 900px) {
    display: 200px;
    font-size: 24px;
  }
}

.tech__col{
  flex-direction: row;
  
  @media (max-width: 1279px) {
    max-width: 500px;
  }

  @media (max-width: 1090px) {
    max-width: 420px;
  }

  @media (max-width: 1045px) {
    max-width: 380px;
  }

  @media (max-width: 990px) {
    max-width: 310px;
  }

  @media (max-width: 900px) {
    display: 300px;
    font-size: 24px;
  }
}

.icon{
  align-items: center;
}



.sk-ww-linkedin-page-post {
  width: 100%; /* Ensure the embed scales within the container */
  height: 100%;
}

.news__title {
  font-size: 36px;
  font-family: IBM Plex Sans;
  font-weight: 700;
  margin-bottom: 50px;
  margin-top: 150px;
  text-align: center;

  @media (max-width: 1279px) {
    font-size: 24px;
  }
}

.products__page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  height: 700px;
  padding: 70px 40px;
  background-image: url("/assets/products_bg.png");
  background-size: cover;
  background-position: center;
  position: relative; 

  @media (max-width: 900px) {
    flex-direction: column;
    padding: 70px 20px;
  }
}

.products__title {
  max-width: 700px;
  text-align: center;
  font-size: 40px;
  font-family: IBM Plex Sans;
  font-weight: 700;
  margin-top: 150px;
  margin-bottom: 20px;

  @media (max-width: 1279px) {
    font-size: 24px;
  }
}

.products__subtitle {
  max-width: 700px;
  text-align: center;
  font-size: 20px; 
  font-family: IBM Plex Sans;
  font-weight: 400;

  @media (max-width: 900px) {
    font-size: 16px; 
  }
}

.features {
  text-align: center;
  padding: 20px;
}

.features__title {
  text-align: center;
  font-size: 40px;
  font-family: IBM Plex Sans;
  font-weight: 700;
  margin-top: 100px;

  @media (max-width: 1279px) {
    font-size: 24px;
  }
}

.features__subtitle {
  font-size: 20px;
  font-family: IBM Plex Sans;
  font-weight: 700;
  margin: 0 0 20px 0;
  text-align: center;

  @media (max-width: 1279px) {
    font-size: 18px;
    text-align: center;
  }
}

.features__image--brightlight {
  max-width: 100%;
  height: auto;
  margin: 40px;
}

.features__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 20px;
  padding-left: 0;
  margin: 50px;
}

.features__coloumn {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
  padding-left: 0;
}

.features__textblock {
  max-width: 50%;
  padding: 20px;
}

.features__text {
  font-size: 16px;
  font-family: IBM Plex Sans;
  font-weight: 400;
  line-height: 1.5;
  max-width: 500px;

  @media (max-width: 1279px) {
    font-size: 14px;
    text-align: left;
  }
}

@media (max-width: 900px) {
  .features__row {
    flex-direction: column;
    text-align: center;
    margin: 20px;
  }

  .features__textblock {
    max-width: 100%;
    padding: 10px;
  }

  .features__image--brightlight {
    margin: 20px 0;
  }

  .colreverse {
    flex-direction: column-reverse;
  }
}


.tech__image{
  
  @media(max-width: 1279px) {
    max-height: 702px;
  }

  @media(max-width: 1152px) {
    max-height: 552px;
  }

  @media(max-width: 900px) {
    max-height: 452px;
  }

  @media(max-width: 600px) {
    max-height: auto;
    height: auto;
    padding: 80px 0 223px;
    margin-top: 59px;
  }
}

.touch__button{
  display: inline-flex;
  padding: 20px 40px;
  box-shadow: 0px 0px 75px #087BFB, 0px 5px 95px #087BFB;  
  border: 1px solid #087BFB;
  border-radius: 40px;
  background-color: rgba(1, 12, 25, 1);
  font-weight: 600;
  font-family: 'IBM Plex Sans';
  font-size: small;
  cursor: pointer;
  color: white;
  text-decoration: none;
  margin-top: 70px;
  align-self: center;
  margin-bottom: 100px;
}

.job__button{
  display: inline-flex;
  padding: 20px 40px;
  box-shadow: 0px 0px 75px #087BFB, 0px 5px 95px #087BFB;  
  border: 1px solid #087BFB;
  border-radius: 40px;
  background-color: rgba(1, 12, 25, 1);
  font-weight: 600;
  font-family: 'IBM Plex Sans';
  font-size: small;
  cursor: pointer;
  color: white;
  text-decoration: none;
  margin-top: 30px;
  align-self: center;
  margin-bottom: 100px;
}

.GetInTouch{
  align-items: center;
  text-align: center;
}

.tech__row{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px; 
  gap: 20px; 
}


.homeHero {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  color: white;
}

.satelliteWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 1;
}

.satellite {
  position: absolute;
  animation: orbit 20s linear infinite;
}

@keyframes orbit {
  0% {
    transform: translate(0, 0) rotate(0deg);
    top: 20%;
    left: 50%;
  }
  25% {
    transform: translate(-50%, 0) rotate(90deg);
    top: 50%;
    left: 90%;
  }
  50% {
    transform: translate(-50%, -50%) rotate(180deg);
    top: 80%;
    left: 50%;
  }
  75% {
    transform: translate(0, -50%) rotate(270deg);
    top: 50%;
    left: 10%;
  }
  100% {
    transform: translate(0, 0) rotate(360deg);
    top: 20%;
    left: 50%;
  }
}

.rnd__title{
  text-align: center;
  font-size: 40px;
  font-family: IBM Plex Sans;
  font-weight: 700;
  margin-bottom: 100px;

  @media (max-width: 1279px) {
    font-size: 24px;
  }
}

.rnd__subtitle {
  text-align: left;
  margin-left: 200px;
  font-size: 22px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 600;
  margin-bottom: 15px;

  @media (max-width: 1279px) {
    font-size: 18px;
    margin-left: 100px;
  }

  @media (max-width: 768px) {
    font-size: 16px;
    margin-left: 20px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
    margin-left: 20px;
    text-align: center;
  }
}

.rnd__text {
  text-align: left;
  margin-left: 200px;
  font-size: 18px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  margin-bottom: 20px;

  @media (max-width: 1279px) {
    font-size: 15px;
    margin-left: 100px;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    margin-left: 20px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
    margin: 0 20px 0 20px;
    text-align: center;
  }
}

.rnd__text1 {
  text-align: center;
  margin-top: 50px;
  margin-left: 50px;

  width: 80%;
  font-size: 15px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  margin-bottom: 20px;

  @media (max-width: 1279px) {
    font-size: 15px;
    margin-left: 100px;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    margin-left: 20px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
    margin: 0 20px 0 20px;
    text-align: center;
  }
}

.location_homepage {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 900px) {
    margin-top: 100px;    
  }
}

.RnD__outline {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 900px) {
    display: none;    
  }
}

.RnD__outline--mobile{
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.RnD__page{
  background-image: url('/assets/stars.png');
  padding-bottom: 100px;
  padding-top: 150px;

}

@media (max-width: 767px) {
  .mobile-image {
    display: block;
  }
  .desktop-image {
    display: none;
  }
}

@media (min-width: 768px) {
  .mobile-image {
    display: none;
  }
  .desktop-image {
    display: block;
  }
}

.ongoing_projects{
  margin: 50px;
}

.spacing {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 20px;
  flex: 1;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
  }

}

.specific {
  margin-top: 10px;
  font-size: 16px;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
  }
}

.rndcol__subtitle {
  font-size: 20px;
  font-family: IBM Plex Sans;
  font-weight: 700;
  text-align: center;
  align-items: center;
  margin-top: 50px;

  @media (max-width: 1279px) {
    font-size: 12px;
    text-align: center;
  }
}

.enquiry__title{
  font-size: 40px;
  font-family: IBM Plex Sans;
  font-weight: 700;
  margin: 200px 200px 20px 200px;


  @media (max-width: 1279px) {
    font-size: 30px;
    margin-left: 100px;
  }

  @media (max-width: 768px) {
    font-size: 25px;
    margin-left: 20px;
  }

  @media (max-width: 480px) {
    font-size: 20px;
    margin: 30px 20px 10px 20px;
    text-align: center;
  }
}

.enquiry__text{
  text-align: left;
  font-size: 18px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  margin: 0 200px 20px 200px;

  @media (max-width: 1279px) {
    font-size: 15px;
    margin-left: 100px;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    margin-left: 20px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
    margin: 0 20px 0 20px;
    text-align: center;
  }
}

.typeform{
  margin: 0 200px 100px 200px;

  @media (max-width: 1279px) {
    margin-left: 100px;
  }

  @media (max-width: 768px) {
    margin-left: 20px;
  }

  @media (max-width: 480px) {
    margin: 10px;
  }

}

.footer__button a {
  text-decoration: none;
}

.responsiveImage1 {
  width: 50%;
  height: auto; 
}

.responsiveImage2 {
  width: 40%;
  height: auto; 
}

.responsiveImage3 {
  width: 30%;
  height: auto; 
}