.navbar {
    position: fixed;
    margin: auto;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    padding: 20px 20px 15px 20px;
    background-color: transparent;
    transition: background-color 0.3s, box-shadow 0.3s;
    z-index: 1000;
}

.navbarContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: auto;
}
  
.sticky {
    background-color: black;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
  
.logo a {
    font-size: 20px;
    font-weight: bold;
    color: white;
    text-decoration: none;
    cursor: pointer;
}

.menu {   

    display: flex;
    
    div {
        margin-right: 20px;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        color: white;
        text-decoration: none;
        cursor: pointer;
    }
}
  
@media (max-width: 1279px) {
    .navbar {
      display: none;
    }
}

.hamburgerMenu {
    display: flex;
    justify-content: space-between;
    z-index: 1000000;
    padding: 10px 20px;
    align-items: center;
    position: fixed;
    top: 0;
    width: -webkit-fill-available;
    background: black;    
    z-index: 1000000;


    .mobileLogo {
        cursor: pointer;
    }

    .menuIcon {
        display: inline-block;
        width: 30px;
        height: 20px;
        cursor: pointer;
        position: relative;
    }
    
    .bar {
        display: block;
        width: 100%;
        height: 4px;
        background-color: white;
        position: absolute;
        left: 0;
        transition: transform 0.3s ease-in-out;
    }
    
    .bar:after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background-color: white;
        position: absolute;
        transition: transform 0.3s ease-in-out;
    }

    .bar:after {
        top: 8px;
    }

    .barOpen {
        top: 8px;
        transform: rotate(45deg);
    }

    .barOpen::after {
        top: 0;
        transform: rotate(-90deg);
    }
    
    #menuToggle {
        display: none;
    }
    
    #menuToggle:checked ~ .mobileMenu {
        transform: translateX(-282px);
    }
    
    #menuToggle:no ~ .mobileMenu {
        transform: translateX(0);
    }
    
    .mobileMenu {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        gap: 15px;
        padding-right: 20px;
        position: relative;
        margin: 0;
        position: fixed;
        top: 0;
        right: -282px;
        height: 100vh;
        width: 260px;
        transition: transform 0.3s ease-in-out;
        z-index: -1;
        border-left: 2px solid #087BFB;
    }

    .mobileMenuBkg {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.1); /* Adjust the alpha value as desired */
        backdrop-filter: blur(10px); 
        z-index: -1;
    }
    
    .mobileMenu div {
        text-decoration: none;
        font-size: 16px;
        font-weight: 600;
        font-size: 24px;
        line-height: 31px;
        text-transform: uppercase;
        cursor: pointer;
    }

    @media (min-width: 1280px) {
        display: none;
    }
}