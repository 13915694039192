.container {
    display: inline-block;
    background: linear-gradient(180deg, #087BFB 0%, rgba(0, 0, 0, 0) 100%);
}
  
.border {
    padding: 1px;
}
  
.button {
    font-weight: 700;
    background-color: black;
    color: white;
    border: none;
    padding: 12px 50px;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
}